<template>
  <div class="flex-grow">
    <section>
      <div class="bg-white overflow-hidden">
        <div class="px-4 py-5">
          <img
            class="mx-auto"
            src="@/assets/homeBanner.jpg"
            alt="A banner image with a photo of Jonathan Powell on the left, the words 'Arete Publishing Ltd' and a mountain range in the background."
          />
        </div>
      </div>
    </section>
    <div class="container mx-auto px-4">
      <slot />
    </div>
  </div>
</template>
<script setup></script>
<style module></style>
