import api from './api'

export const createPost = payload => {
  return api.post('post/create.php', payload)
}

export const updatePost = payload => {
  return api.post('post/update.php', payload)
}

export const deletePost = id => {
  return api.delete('post/delete.php', {
    data: {
      id,
    },
  })
}

export const uploadImage = payload => {
  return api.post('image/upload.php', payload)
}
