/* https://github.com/FortAwesome/vue-fontawesome */

import { app } from '@/main'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import {
  faEnvelope,
  faClipboard,
  faSpinner,
  faEdit,
  faTrash,
  faCheck,
  faTimes,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
library.add(
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutube,
  faEnvelope,
  faClipboard,
  faSpinner,
  faEdit,
  faTrash,
  faCheck,
  faTimes
)

app.component('fa-icon', FontAwesomeIcon)
