import Cookies from 'js-cookie'
import { createApp } from 'vue'
import store from './store/index.js'
import router from './router'
import App from './App.vue'
import { registerBaseComponents, loadPlugins } from '@/helpers'
import './assets/tailwind.css'

Cookies.set('ad_storage', 'denied')
Cookies.set('ads_data_redaction', 'true')
Cookies.set('analytics_storage', 'denied')

export const app = createApp(App)
registerBaseComponents(app)
loadPlugins(['vue-fontawesome', 'vue-recaptcha'])
app.use(store)
app.use(router)

app.mount('#app')
