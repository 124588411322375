<template>
  <component :is="currentLayout"> <slot /></component>
</template>
<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import Default from './Default'
import StaticWithBanner from './StaticWithBanner'

const route = useRoute()

const LAYOUT_MAP = {
  default: Default,
  staticWithBanner: StaticWithBanner,
}

const currentLayout = computed(() => {
  const { layout = 'default' } = route.meta

  return LAYOUT_MAP[layout] || LAYOUT_MAP.default
})
</script>
<style module></style>
