import { createPost, updatePost } from '@/api/blogApi'

export default {
  async addPost(context, data) {
    const dateNow = new Date()
    const postData = {
      title: data.title,
      slug: data.title.replace(/[\W_]+/g, '-').toLowerCase(),
      category_id: data.categoryId,
      //tags: '',
      description: data.desc,
      body: data.body,
      datePosted: dateNow.toString(),
      reading_time: data.readTime,
      author: data.author,
      token: context.rootGetters.token,
    }

    const response = await createPost(postData)
    if (response.data.error) {
      throw new Error(response.data.message || 'Failed to create post!')
    }

    context.commit('addPost', {
      id: response.data.id,
      ...postData,
    })

    return postData
  },
  async updatePost(context, data) {
    const postData = {
      id: data.id,
      title: data.title,
      slug: data.title.replace(/[\W_]+/g, '-').toLowerCase(),
      category_id: data.categoryId,
      //tags: '',
      description: data.desc,
      body: data.body,
      reading_time: data.readTime,
      author: data.author,
      date_posted: data.datePosted,
      token: context.rootGetters.token,
    }
    const response = await updatePost(postData)

    if (response.data.error) {
      throw new Error(response.data.message || 'Failed to update post!')
    }

    context.commit('updatePost', {
      id: postData.id,
      title: postData.title,
      slug: postData.slug,
      categoryId: postData.category_id,
      description: postData.description,
      body: postData.body,
      datePosted: postData.date_posted,
      readingTime: postData.reading_time,
      author: postData.author,
    })

    return postData
  },
  async loadPosts(context, payload) {
    if (!payload.forceRefresh && !context.getters.shouldUpdate) {
      return
    }
    try {
      const response = await fetch(
        `${process.env.VUE_APP_SERVER_URL}/api/post/read.php`
      )
      const responseData = await response.json()

      if (!response.ok) {
        const error = new Error(responseData.message || 'Failed to fetch!')
        throw error
      }

      const posts = []

      for (const key in responseData.data) {
        const post = {
          id: responseData.data[key].id,
          title: responseData.data[key].title,
          slug: responseData.data[key].slug,
          categoryId: responseData.data[key].category_id,
          //tags: '',
          categoryName: responseData.data[key].category_name?.replace(
            '&amp;',
            '&'
          ),
          description: responseData.data[key].description,
          body: responseData.data[key].body,
          datePosted: responseData.data[key].date_posted?.replace(/-/g, '/'),
          readingTime: responseData.data[key].reading_time,
          author: responseData.data[key].author,
        }
        posts.push(post)
      }
      context.commit('setPosts', posts)
      context.commit('setFetchTimestamp')
    } catch (error) {
      throw new Error('There was a problem with fetching the blog posts.')
    }
  },
}
