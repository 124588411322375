<template>
  <the-header></the-header>
  <router-view v-slot="{ Component, route }">
    <transition name="route" mode="out-in">
      <Layout :key="route.path">
        <component :is="Component"></component>
      </Layout>
    </transition>
  </router-view>
  <TheFooter />
  <CookiesOverlay />
</template>

<script>
import TheHeader from './components/layout/TheHeader.vue'
import TheFooter from './components/layout/TheFooter.vue'
import { useQueryProvider } from 'vue-query'
import Layout from './layout/Layout.vue'
import CookiesOverlay from './components/CookiesOverlay.vue'
export default {
  components: {
    TheHeader,
    TheFooter,
    Layout,
    CookiesOverlay,
  },
  setup() {
    useQueryProvider({
      refetchOnWindowFocus: false,
    })
  },
  computed: {
    didAutoLogout() {
      return this.$store.getters.didAutoLogout
    },
  },
  created() {
    this.$store.dispatch('tryLogin')
  },
  watch: {
    didAutoLogout(curValue, oldValue) {
      if (curValue && curValue !== oldValue) {
        this.$router.replace('/blog')
      }
    },
  },
}
</script>

<style>
html,
body {
  min-height: 100vh;
}
#app {
  min-height: inherit;
  display: flex;
  flex-direction: column;
}
.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
