<template>
  <footer v-once class="text-center px-4 py-6 bg-gray-900 mx-0">
    <section class="text-gray-50 space-y-4">
      <nav>
        <ul class="flex items-center justify-center space-x-4">
          <li>
            <router-link
              class="hover:text-gray-500 transition duration-200"
              to="/cookies"
              >Cookies</router-link
            >
          </li>
          <li>
            <router-link
              class="hover:text-gray-500 transition duration-200"
              to="/privacy-policy"
              >Privacy Policy</router-link
            >
          </li>
          <li>
            <router-link
              class="hover:text-gray-500 transition duration-200"
              to="/terms-of-use"
              >Terms of Use</router-link
            >
          </li>
        </ul>
      </nav>
      <div>&copy; Copyright Aret&eacute; Publishing - {{ year }}</div>
      <div class="flex justify-center items-center">
        <span class="text-sm sm:text-md">Made in the</span>
        <img class="mx-2" src="@/assets/gb-flag.png" alt="UK flag" />
        <span class="text-sm sm:text-md">by&nbsp;</span>
        <a
          class="text-sm sm:text-md"
          href="https://jimmajammalulu.co.uk/"
          target="_blank"
          rel="noreferrer noopener"
          >Jimma Jamma Lulu</a
        >
      </div>
    </section>
  </footer>
</template>
<script setup>
const year = new Date().getFullYear()
</script>
