<template>
  <div v-if="showCookiesBanner" :class="$style.overlay">
    <div class="bg-white w-full max-w-[550px]">
      <div :class="['p-4 flex items-center', $style.header]">
        <h2 class="text-lg md:text-xl font-semibold text-indigo-100">
          Cookies banner
        </h2>
      </div>

      <div class="mb-4 px-4 mt-4">
        We only set cookies which are essential to the proper functioning of
        this website. These cookies do not store your personal data or share it
        with any third party. For more information please refer to our
        <router-link :class="$style.cookiesLink" to="/cookies"
          >cookie policy</router-link
        >
        and
        <router-link :class="$style.cookiesLink" to="/privacy-policy"
          >privacy policy</router-link
        >.
      </div>
      <div class="flex justify-end px-4 mb-4">
        <BaseButton @click.prevent="acceptCookies">Allow cookies</BaseButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
const noCookiesPages = ['/cookies', '/terms-of-use', '/privacy-policy']
const route = useRoute()
const showCookiesBanner = ref(false)

/**
 * Initially, when this component is created, the route.path is set to "/". Therefore, we need to watch the path and wait for it to change to the path of the loaded page.
 */
const unsubCookiesWatch = watch(
  () => route.path,
  path => {
    // Hide the Cookie overlay on privacy pages
    if (noCookiesPages.includes(path)) {
      showCookiesBanner.value = false
      return
    }
    // Otherwise, set the value to the one from the local storage
    showCookiesBanner.value = !window.localStorage.getItem('cookiesAccepted')
  }
)

if (showCookiesBanner.value) {
  window.document.body.classList.add('overflow-hidden')
}

const onGoToCookiesPage = () => {
  showCookiesBanner.value = false
  window.document.body.classList.remove('overflow-hidden')
}

const acceptCookies = () => {
  window.localStorage.setItem('cookiesAccepted', true)
  window.document.body.classList.remove('overflow-hidden')
  showCookiesBanner.value = false
  unsubCookiesWatch()
}
</script>
<style module>
.overlay {
  @apply fixed z-50 bg-gray-600/50 flex items-center justify-center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.header {
  background-color: #3a0051;
}

.cookiesLink {
  @apply !text-blue-500 hover:!text-blue-600;
}
</style>
