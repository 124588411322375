import { createRouter, createWebHistory } from 'vue-router'
import { loadView } from './routerUtils'
const BlogArchive = loadView('blog/BlogArchive')
const BlogPost = loadView('blog/ViewBlogPost/ViewBlogPost')
const EditPost = loadView('blog/EditPost')
const NewPost = loadView('blog/NewPost')
const StaticPage = loadView('StaticPage')
const About = loadView('About')
const UserAuth = loadView('auth/UserAuth')
const NotFound = loadView('NotFound')
const Cookies = loadView('Cookies')
const Privacy = loadView('privacy/Privacy')
const Terms = loadView('Terms')

import store from '@/store'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior() {
    return {
      top: 0,
    }
  },
  routes: [
    { path: '/', redirect: '/blog' },
    { path: '/blog', component: BlogArchive },
    {
      path: '/blog/:slug/edit',
      component: EditPost,
      props: true,
      meta: { requiresAuth: true },
    },
    { path: '/blog/new', component: NewPost, meta: { requiresAuth: true } },
    {
      path: '/blog/:slug',
      name: 'ViewBlogPost',
      component: BlogPost,
      props: true,
    },
    { path: '/about-me-jonathan-powell', component: About },
    { path: '/login', component: UserAuth, meta: { requiresUnauth: true } },
    {
      path: '/cookies',
      component: Cookies,
      meta: {
        layout: 'staticWithBanner',
      },
    },
    {
      path: '/privacy-policy',
      component: Privacy,
      meta: {
        layout: 'staticWithBanner',
      },
    },
    {
      path: '/terms-of-use',
      component: Terms,
      meta: {
        layout: 'staticWithBanner',
      },
    },
    {
      path: '/not-found',
      component: NotFound,
    },
    {
      path: '/:notFound(.*)',
      redirect: to => {
        // Redirect to the blog page to try loading a blog post
        // If a post is not found then the blog page will redirect to /not-found
        const splittedPath = to.fullPath.split('/').filter(Boolean)
        if (splittedPath.length === 1) {
          return {
            path: `blog${to.fullPath}`,
            query: {
              from: 'catch-all',
            },
            params: {
              slug: splittedPath?.[0],
            },
            name: 'ViewBlogPost',
          }
        } else {
          return {
            path: '/not-found',
          }
        }
      },
    },
  ],
})

router.beforeEach(function (to, from, next) {
  if (to.meta.requiresAuth && !store.getters.isAuthenticated) {
    next('/login')
  } else if (to.meta.requiresUnauth && store.getters.isAuthenticated) {
    next('/blog')
  } else {
    next()
  }
})

export default router
