<template>
  <teleport to="body">
    <div>
      <transition name="backdrop">
        <div v-if="show" @click="tryClose" class="backdrop"></div>
      </transition>
      <transition name="dialog">
        <dialog open v-if="show">
          <div ref="trapTarget">
            <slot name="header">
              <header>
                <h2>{{ title }}</h2>
              </header>
            </slot>
            <section v-if="$slots.default">
              <slot></slot>
            </section>
            <menu v-if="!fixed">
              <slot name="actions">
                <base-button @click="tryClose">Close</base-button>
              </slot>
            </menu>
          </div>
        </dialog>
      </transition>
    </div>
  </teleport>
</template>

<script>
import { nextTick, ref, watch } from 'vue'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableFocusTrap: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(props) {
    const trapTarget = ref()
    const { activate, deactivate } = useFocusTrap(trapTarget, {
      immediate: props.enableFocusTrap ? true : false,
    })

    if (props.enableFocusTrap) {
      watch(
        () => props.show,
        async isOpen => {
          /**
           * The watch runs before the DOM updates are flushed
           * If the dialog was just opened, we need to wait for it to render, so we await for the next tick
           * However, the deactivation must happen before the DOM update, as otherwise the useFocusTrap won't be able to remove event listeners from the trap target
           */
          if (isOpen) {
            await nextTick()
            activate()
          } else {
            deactivate()
          }
        },
        {
          flush: 'pre',
        }
      )
    }
    return {
      trapTarget,
    }
  },
  methods: {
    tryClose() {
      if (this.fixed) {
        return
      }
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 10;
}

dialog {
  position: fixed;
  top: 20vh;
  left: 10%;
  width: 80%;
  z-index: 100;
  border-radius: 12px;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 0;
  margin: 0;
  overflow: hidden;
  background-color: white;
}

header {
  background-color: #3a0061;
  color: white;
  width: 100%;
  padding: 1rem;
}

header h2 {
  margin: 0;
}

section {
  padding: 1rem;
}

menu {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.dialog-enter-from,
.dialog-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.dialog-enter-active {
  transition: all 0.3s ease-out;
}

.dialog-leave-active {
  transition: all 0.3s ease-in;
}

.dialog-enter-to,
.dialog-leave-from {
  opacity: 1;
  transform: scale(1);
}

.backdrop-enter-from,
.backdrop-leave-to {
  opacity: 0;
}

.backdrop-enter-active {
  transition: all 0.1s ease-out;
}

.backdrop-leave-active {
  transition: all 0.2s ease-in;
}

.backdrop-enter-to,
.backdrop-leave-from {
  opacity: 1;
}

@media (min-width: 768px) {
  dialog {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}
</style>
