<template>
  <button
    v-if="!link"
    :class="mode"
    class="inline-flex items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <slot></slot>
  </button>
  <router-link
    v-else
    :to="to"
    :class="mode"
    class="inline-flex items-center bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-blue-gray-900 hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
  >
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      required: false,
      default: null,
    },
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: '/',
    },
  },
}
</script>

<style scoped>
/*
	button,
	a {
		text-decoration: none;
		padding: 0.75rem 1.5rem;
		font: inherit;
		background-color: #3a0061;
		border: 1px solid #3a0061;
		color: white;
		cursor: pointer;
		border-radius: 30px;
		margin-right: 0.5rem;
		display: inline-block;
	}
	
	a:hover,
	a:active,
	button:hover,
	button:active {
		background-color: #270041;
		border-color: #270041;
	}
	
	.flat {
		background-color: transparent;
		color: #3a0061;
		border: none;
	}
	
	.outline {
		background-color: transparent;
		border-color: #270041;
		color: #270041;
	}
	
	.flat:hover,
	.flat:active,
	.outline:hover,
	.outline:active {
		background-color: #edd2ff;
	}
	*/
</style>
